const TAXON_TAG_MAP = {
  "10% off": { name: "10% off", slug: "sale" },
  "15% off": { name: "15% off", slug: "sale" },
  "20% off": { name: "20% off", slug: "sale" },
  "25% off": { name: "25% off", slug: "sale" },
  "30% off": { name: "30% off", slug: "sale" },
  "35% off": { name: "35% off", slug: "sale" },
  "40% off": { name: "40% off", slug: "sale" },
  "45% off": { name: "45% off", slug: "sale" },
  "50% off": { name: "50% off", slug: "sale" },
  "55% off": { name: "55% off", slug: "sale" },
  "60% off": { name: "60% off", slug: "sale" },
  "65% off": { name: "65% off", slug: "sale" },
  "New Products": { name: "new", slug: "new" },
  "Staff Picks": { name: "staff pick", slug: "staff-pick" },
  Bestseller: { name: "bestseller", slug: "bestseller" },
  Trending: { name: "trending", slug: "trending" },
  "Free Shipping": { name: "free shipping", slug: "sale" },
}

export const createProductTags = (
  marketingTaxons,
  isDiscontinued = false,
  amount = 3,
) => {
  const labels = []
  if (marketingTaxons) {
    marketingTaxons.forEach((taxon) => {
      if (TAXON_TAG_MAP[taxon]) {
        labels.push(TAXON_TAG_MAP[taxon])
      }
    })
    if (isDiscontinued) {
      labels.push({ name: "discontinued", slug: "discontinued" })
    }
    return labels.slice(0, amount)
  }
  return []
}
