<template>
  <div class="rating" itemscope itemtype="https://schema.org/AggregateRating">
    <meta itemprop="ratingValue" :content="rating" />
    <meta
      v-if="reviewCount > 0"
      itemprop="reviewCount"
      :content="reviewCount"
    />
    <span class="at-text">
      {{ ratinglabel }}
    </span>

    <SvgIcon
      v-for="star in [...Array(rating)]"
      :key="star"
      class="star filled"
      aria-hidden="true"
      icon="star"
    />
    <SvgIcon
      v-for="restStar in [...Array(rest)]"
      :key="restStar"
      class="star"
      aria-hidden="true"
      icon="star"
    />
  </div>
</template>

<script>
  export default {
    props: {
      rating: { type: Number, default: 3 },
      reviewCount: { type: Number, default: 0 },
    },
    computed: {
      rest() {
        return 5 - this.rating
      },
      ratinglabel() {
        return `${this.rating} out of 5 Stars.`
      },
    },
  }
</script>

<style lang="scss" scoped>
  .rating {
    display: inline-grid;
    grid-auto-flow: column;
    grid-gap: 1px;
    align-items: center;
    height: $base-spacing * 4;
  }

  .star {
    width: 13px;
    height: 13px;
    fill: $gray-200;
  }

  .at-text {
    @extend %at-text;
  }

  .star.filled {
    fill: $yellow-shape;
    .cs-dark & {
      fill: $yellow;
    }
  }
</style>
